import React, { Component, Fragment } from 'react';

import Moment from 'moment';
import 'moment/min/locales';
import { NavLink, UncontrolledTooltip } from 'reactstrap';
import createTooltip from './utils/createTooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLock, faLockOpen, faSyncAlt, faVideo, faHandshake} from '@fortawesome/free-solid-svg-icons';
import {faClone} from '@fortawesome/free-regular-svg-icons';

class Event extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    render() {
        const { event, date, column } = this.props;

        let timeDisplay;
        let timeDisplayEnd;
        if (typeof event.start.dateTime === 'undefined') {
            timeDisplay = '[All day]';
        } else {
            if (Moment(event.start.dateTime).isSame(Moment(event.end.dateTime), 'day')) {
                // event starts/ends on the same day
                timeDisplay = Moment(event.start.dateTime).format('LT');
                timeDisplayEnd = '-' + Moment(event.end.dateTime).format('LT');
            } else {
                // event spans multiple days
                // display either the beginning, middle or end portion
                if (date.clone().isSame(Moment(event.start.dateTime), 'day')) {
                    timeDisplay = Moment(event.start.dateTime).format('LT');
                    timeDisplayEnd = '-' + date.clone().endOf('day').format('LT');
                } else if (date.clone().isSame(Moment(event.end.dateTime), 'day')) {
                    timeDisplay = date.clone().startOf('day').format('LT');
                    timeDisplayEnd = '-' + Moment(event.end.dateTime).format('LT');
                } else {
                    timeDisplay = date.clone().startOf('day').format('LT');
                    timeDisplayEnd = '-' + date.clone().endOf('day').format('LT');
                }
            }
        }

        const toolTip = createTooltip(event);

        // TODO allow HTML in the tooltip
        // can't figure out passing modifiers such as contentType: 'html'
        // in the popper.js library
        // contentType='html' modifiers={{contentType: 'html'}}

        const readOnly = ((event.visibility && event.visibility === 'private')
            || (event.accessRole && (event.accessRole === 'reader' || event.accessRole === 'freeBusyReader')));

        const tooltipTextSpansMultipleDays = 'Spans multiple days';
        const tooltipTextRepeats = 'Repeats';
        const tooltipTextReadonly = 'Calendar is read only';
        const tooltipTextPrivate = 'Appointment is private';

        let conferenceSolutionName;
        let conferenceSolutionIcon = faHandshake;

        if (typeof event.conferenceData !== 'undefined') {

            if (event.conferenceData.conferenceSolution.name === 'Google Meet' && !this.props.preferences.ignoreGoogleMeet) {
                conferenceSolutionName = event.conferenceData.conferenceSolution.name;
            }
            if (event.conferenceData.conferenceSolution.name === 'Zoom Meeting' && !this.props.preferences.ignoreZoomMeeting) {
                conferenceSolutionName = event.conferenceData.conferenceSolution.name;
            }

            // change the icon from a handshake to a video camera if we know it is a video meeting
            for (let i = 0; i < event.conferenceData.entryPoints.length; i++) {
                if (event.conferenceData.entryPoints[i].entryPointType === 'video') {
                    conferenceSolutionIcon = faVideo;
                }
            }
        }

            return (
            <Fragment>
                {readOnly &&
                <Fragment>
                    <span className="EventCell">
                            {event.recurringEventId &&
                            <Fragment>
                                <FontAwesomeIcon
                                    className="mr-1"
                                    id={'R1' + column + event.id}
                                    icon={faSyncAlt}/>
                                <UncontrolledTooltip target={'R1' + column + event.id} placement="top">{tooltipTextRepeats}</UncontrolledTooltip>
                            </Fragment>}
                        {conferenceSolutionName && conferenceSolutionName !== '' &&
                        <Fragment>
                            <FontAwesomeIcon
                                className="mr-1"
                                id={'V2' + column + event.id}
                                icon={conferenceSolutionIcon}/>
                            <UncontrolledTooltip target={'V2' + column + event.id} placement="top">{conferenceSolutionName}</UncontrolledTooltip>
                        </Fragment>}
                        {event.start.dateTime && !Moment(event.start.dateTime).isSame(Moment(event.end.dateTime), 'day') &&
                            <Fragment>
                                <FontAwesomeIcon
                                    className="mr-1"
                                    id={'S1' + column + event.id}
                                    icon={faClone}/>
                                <UncontrolledTooltip target={'S1' + column + event.id} placement="top">{tooltipTextSpansMultipleDays}</UncontrolledTooltip>
                            </Fragment>}
                            {readOnly &&
                            <Fragment>
                                <FontAwesomeIcon
                                    className="mr-1"
                                    id={'L1' + column + event.id}
                                    icon={event.summary ? faLockOpen : faLock}/>
                                <UncontrolledTooltip target={'L1' + column + event.id} placement="top">
                                    {event.summary ? tooltipTextReadonly : tooltipTextPrivate}
                                </UncontrolledTooltip>
                            </Fragment>}
                            <span id={'T1' + column + event.id}>{timeDisplay}{timeDisplayEnd && <span className='EventCellEndTime'>{timeDisplayEnd}</span>}
                                {' ' + (event.summary ? event.summary : 'private')}
                            </span>
                        <UncontrolledTooltip target={'T1' + column + event.id} placement="right">
                            {toolTip}
                        </UncontrolledTooltip>
                        </span>
                </Fragment>
                }
                {!readOnly &&
                <Fragment>
                    <NavLink
                        className="EventCell"
                        onClick={() => this.props.openModal(event)}>
                        {/* eslint-disable-next-line no-mixed-operators */}
                        {(event.start.dateTime && !Moment(event.start.dateTime).isSame(Moment(event.end.dateTime), 'day') ||
                            (event.start.date && Moment(event.end.date).subtract(1, 'day').isAfter(Moment(event.start.date), 'day'))) &&
                        <Fragment>
                            <FontAwesomeIcon
                                className="mr-1"
                                id={'S2' + column + event.id}
                                icon={faClone}/>
                            <UncontrolledTooltip target={'S2' + column + event.id} placement="top">{tooltipTextSpansMultipleDays}</UncontrolledTooltip>
                        </Fragment>}
                        {conferenceSolutionName && conferenceSolutionName !== '' &&
                        <Fragment>
                            <FontAwesomeIcon
                                className="mr-1"
                                id={'V2' + column + event.id}
                                icon={conferenceSolutionIcon}/>
                            <UncontrolledTooltip target={'V2' + column + event.id} placement="top">{conferenceSolutionName}</UncontrolledTooltip>
                        </Fragment>}
                        {event.recurringEventId &&
                        <Fragment>
                            <FontAwesomeIcon
                                className="mr-1"
                                id={'R2' + column + event.id}
                                icon={faSyncAlt}/>
                            <UncontrolledTooltip target={'R2' + column + event.id} placement="top">{tooltipTextRepeats}</UncontrolledTooltip>
                        </Fragment>}
                        <span id={'T2' + column + event.id}>{timeDisplay}{timeDisplayEnd && <span className='EventCellEndTime'>{timeDisplayEnd}</span>}
                            {' ' + event.summary}
                            </span>
                    </NavLink>
                    <UncontrolledTooltip target={'T2' + column + event.id} placement="right">{toolTip}</UncontrolledTooltip>
                </Fragment>
                }
            </Fragment>
        );
    }
}

export default Event;
