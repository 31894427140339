import React, {Component, Fragment} from 'react';

import Moment from 'moment';
import 'moment/min/locales';
import {UncontrolledTooltip} from 'reactstrap';

class ObservedDay extends Component {
// eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render() {

        const {observedDay} = this.props;

        let toolTip = '';
        if (typeof observedDay.start.dateTime !== 'undefined') {
            toolTip = Moment(observedDay.start.dateTime).format('LT') + '-' + Moment(observedDay.end.dateTime).format('LT');
            if (observedDay.description) {
                toolTip += ` ${observedDay.description}`;
            }
        }
        if (observedDay.calendarSummary) {
            toolTip += ` (from ${observedDay.calendarSummary})`;
        }

        return (
            <Fragment>
                {toolTip && toolTip !== '' && <UncontrolledTooltip target={'T' + observedDay.id} placement="right">
                    {toolTip}
                </UncontrolledTooltip>}
                <div className='ObservedHoliday mb-1' id={'T' + observedDay.id}>
                    {observedDay.summary}
                </div>
            </Fragment>
        );
    }
}

export default ObservedDay;