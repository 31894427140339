import React, {Component, Fragment} from 'react';

import Moment from 'moment';
import 'moment/min/locales';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import SelectList from 'react-widgets/lib/SelectList';
import 'react-widgets/dist/css/react-widgets.css';

import {ButtonGroup, Button } from 'reactstrap';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {Input, InputGroupButtonDropdown, InputGroup, InputGroupText, InputGroupAddon, DropdownMenu, DropdownItem, DropdownToggle} from 'reactstrap';
import {NavLink, FormFeedback} from 'reactstrap';
import {Container, Row, Col} from 'reactstrap';
import {Form, FormGroup, Label, UncontrolledTooltip, Alert} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExternalLinkAlt, faExclamationTriangle, faHandshake, faVideo} from '@fortawesome/free-solid-svg-icons';

const APPT_REPEAT_OPTION_DOESNT = 'Doesn\'t Repeat';
const REPEAT_MAX_TIMES = 100;

class Appointment extends Component {
    constructor(props) {
        super(props);

        this.formatFinishTime = this.formatFinishTime.bind(this);
        this.toggleRepeatDropDown = this.toggleRepeatDropDown.bind(this);

        this.state = {
            dropdownRepeatOpen: false,
            preferencesValues: [],
        };

        momentLocalizer();
    }

    toggleRepeatDropDown() {
        this.setState({
            dropdownRepeatOpen: !this.state.dropdownRepeatOpen,
        });
    }

    formatFinishTime(value) {

        value=Moment(value).year(this.props.finishTime.year()).month(this.props.finishTime.month()).day(this.props.finishTime.day());

        // TODO display duration in the pop up
        return Moment(value).format('LT');
    }

    render() {

        var hasEditableCalendars = false;
        for (let i = 0; i < this.props.selected.length; i++) {
            if ('undefined' !== typeof this.props.calendars.find(({column, accessRole}) => column === this.props.selected[i] + 1 && (accessRole === 'owner' || accessRole === 'writer'))) {
                hasEditableCalendars = true;
            }
        }

        const apptValid = this.props.summary !== '' &&
            this.props.selected.length !== 0 &&
            hasEditableCalendars &&
            Moment(this.props.startTime).isValid() &&
            Moment(this.props.finishTime).isValid();

        const {dirty} = this.props;

        let conferenceSolutionIcon = faHandshake;
        let conferenceSolutionName;
        let conferenceJoinUri = '';
        let conferenceJoiningInstructionsUri = '';

        if (typeof this.props.conferenceData !== 'undefined' && this.props.conferenceData !== false) {

            // change the icon from a handshake to a video camera if we know it is a video meeting
            for (let i = 0; this.props.conferenceData.entryPoints && i < this.props.conferenceData.entryPoints.length; i++) {
                if (this.props.conferenceData.entryPoints[i].entryPointType === 'video') {
                    conferenceSolutionIcon = faVideo;
                }
            }

            if (this.props.conferenceData.conferenceSolution.name === 'Google Meet' && !this.props.preferences.ignoreGoogleMeet) {
                conferenceSolutionName = this.props.conferenceData.conferenceSolution.name;
            }
            if (this.props.conferenceData.conferenceSolution.name === 'Zoom Meeting' && !this.props.preferences.ignoreZoomMeeting) {
                conferenceSolutionName = this.props.conferenceData.conferenceSolution.name;
            }

            for (let i = 0; this.props.conferenceData.entryPoints && i < this.props.conferenceData.entryPoints.length; i++) {
                if (this.props.conferenceData.entryPoints[i].entryPointType === 'more') {
                    conferenceJoiningInstructionsUri = this.props.conferenceData.entryPoints[i].uri;
                } else {
                    conferenceJoinUri = this.props.conferenceData.entryPoints[i].uri;
                }
            }

        }

        return (
            <Modal backdrop={false} isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}>
                <ModalHeader toggle={this.props.toggle}>Create/Edit Appointment</ModalHeader>
                <ModalBody>
                    <Container>
                    <Form>
                        <FormGroup className="ApptFormGroup mb-1">
                            <ButtonGroup>
                                <Row>
                                    <Col>
                                        {this.props.calendars && this.props.calendars.map((calendar, index) =>
                                            <Button key={calendar.calendarId}
                                                    className='ApptButtonAttendee mr-1 mb-1'
                                                    disabled={!(calendar.accessRole === 'owner' || calendar.accessRole === 'writer')}
                                                    color={this.props.selected.includes(index) ? 'primary' : 'secondary'}
                                                    outline={!this.props.selected.includes(index)}
                                                    onClick={() => this.props.onCheckboxBtnClick(index)}>{calendar.displayName}
                                            </Button>,
                                        )}
                                    </Col>
                                </Row>
                            </ButtonGroup>
                        </FormGroup>
                        <FormGroup className="ApptFormGroup mb-1">
                            <Label className="ApptFormLabel mb-1">Description</Label>
                            <Input name="summary" id="summary" placeholder="enter a summary" onChange={this.props.onChangeSummary} value={this.props.summary}/>
                        </FormGroup>
                        <FormGroup className="ApptFormGroup mb-1">
                            <Label className="ApptFormLabel mb-1">Location</Label>
                            <Input name="location" id="location" placeholder="add a location (optional)" onChange={this.props.onChangeLocation} value={this.props.location}/>
                        </FormGroup>
                        <FormGroup className="ApptFormGroup mb-1">
                            <Label className="ApptFormLabel mb-1">Start</Label>
                            <DateTimePicker format={this.props.isAllDay ? 'LL' : 'LLLL'}
                                            time={!this.props.isAllDay}
                                            onChange={this.props.onChangeStartTime}
                                            value={Moment(this.props.startTime).toDate()}
                                            defaultValue={Moment(this.props.startTime).toDate()}
                                            placeholder="Please enter a valid start date/time"
                            />
                        </FormGroup>
                        {!this.props.isAllDay &&
                        <FormGroup className="ApptFormGroup mb-1">
                            <Label className="ApptFormLabel mb-1">End</Label>
                            <DateTimePicker format='LLLL'
                                            onChange={this.props.onChangeFinishTime}
                                            value={Moment(this.props.finishTime).toDate()}
                                            defaultValue={Moment(this.props.finishTime).toDate()}
                                            min={Moment(this.props.startTime).toDate()}
                                            timeFormat={this.formatFinishTime}
                                            placeholder="Please enter a valid end date/time"
                            />
                        </FormGroup>}
                        <FormGroup className="ApptFormGroup mb-1">
                            <Label className="ApptFormLabel mb-1">Options</Label>
                            <SelectList className="ApptOptions"
                                        multiple
                                        data={this.props.optionsData}
                                        defaultValue={['']}
                                        value={this.props.optionsValues}
                                        onChange={this.props.onChangeOptions}
                            />
                        </FormGroup>
                        {!this.props.isRecurring &&
                        <FormGroup className="ApptFormGroup mb-2">
                            <Label className="ApptFormLabel mb-1">Repeats</Label>
                            <InputGroup>
                                <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.dropdownRepeatOpen} toggle={this.toggleRepeatDropDown}>
                                    <DropdownToggle caret outline size="sm">
                                        {this.props.repeatType}
                                    </DropdownToggle>
                                    <DropdownMenu onClick={(e) => this.props.onChangeRepeatType(`${e.target.textContent}`)}>
                                        {this.props.repeatOptions.map((option, index) =>
                                            <DropdownItem key={index}>{option}</DropdownItem>,
                                        )}
                                    </DropdownMenu>
                                </InputGroupButtonDropdown>
                                {this.props.repeatType !== APPT_REPEAT_OPTION_DOESNT &&
                                <Fragment>
                                    <Input className="mb-1"
                                           defaultValue={2}
                                           min={2}
                                           max={REPEAT_MAX_TIMES}
                                           type="number"
                                           step="1"
                                           invalid={!(this.props.repeatCount >= 2 && this.props.repeatCount <= REPEAT_MAX_TIMES)}
                                           onChange={(e) => this.props.onChangeRepeatCount(`${e.target.value}`)}>
                                    </Input>
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>times through {this.props.repeatFormattedEndDate}</InputGroupText>
                                    </InputGroupAddon>
                                    <FormFeedback >Must be between 2 and {REPEAT_MAX_TIMES}</FormFeedback>
                                </Fragment>}
                            </InputGroup>
                        </FormGroup>}
                        <Fragment>
                            {this.props.selected.length !== 0 && !hasEditableCalendars &&
                            <Alert color='danger' key='999' className='ApptAdditionalElements mb-2'>
                                <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    color='red'
                                    className='mr-1'/>
                                You must include at least one calendar which you have edit access to
                            </Alert>}
                        </Fragment>
                        <Fragment>
                            {this.props.infoElements && this.props.infoElements.map((element, index) =>
                                <Alert color={element.type} key={index} id={'infoElements' + index} className='ApptAdditionalElements mb-2'>
                                    <FontAwesomeIcon
                                        icon={element.faIcon}
                                        color={element.faColour}
                                        className='mr-1'/>
                                    {element.value}
                                    {element.tooltip && element.tooltip !== '' &&
                                    <UncontrolledTooltip target={'infoElements' + index} placement="left">{element.tooltip}</UncontrolledTooltip>
                                    }</Alert>
                            )}
                        </Fragment>
                        {this.props.conferenceData && this.props.conferenceData !== 'undefined' &&
                        <Fragment>
                            {conferenceSolutionName && conferenceSolutionName !== '' && conferenceJoinUri && conferenceJoinUri !== '' &&
                            <Alert color='info' key='999' className='ApptAdditionalElements mb-2'>
                                <FontAwesomeIcon
                                    icon={conferenceSolutionIcon}
                                    color='info'
                                    className='mr-1'/>
                                {conferenceJoinUri !== '' &&
                                <NavLink className="inlineLink"
                                         href={conferenceJoinUri}
                                         target="_window">Join {conferenceSolutionName} now</NavLink>
                                }
                            </Alert>
                            }
                            {conferenceSolutionName && conferenceSolutionName !== '' && conferenceJoiningInstructionsUri && conferenceJoiningInstructionsUri !== '' &&
                            <Alert color='info' key='999' className='ApptAdditionalElements mb-2'>
                                <FontAwesomeIcon
                                    icon={conferenceSolutionIcon}
                                    color='info'
                                    className='mr-1'/>
                                <NavLink className="inlineLink"
                                         href={conferenceJoiningInstructionsUri}
                                         target="_window">Joining instructions <FontAwesomeIcon
                                    fixedWidth
                                    icon={faExternalLinkAlt}/>
                                </NavLink>
                            </Alert>
                            }
                        </Fragment>}
                        <Fragment>
                            {this.props.isRecurring &&
                            <Alert color="info" className='mb-2'>
                                This event is part of a repeating series. You can only change or delete the entire series from {this.props.urlGoogleCalendarEvent !== '' &&
                            <NavLink className="inlineLink"
                                     href={this.props.urlGoogleCalendarEvent}
                                     target="_window">Google Calendar <FontAwesomeIcon
                                fixedWidth
                                icon={faExternalLinkAlt}
                            /></NavLink>}{this.props.urlGoogleCalendarEvent === '' && 'Google Calendar'}. Changes here will only be made to this occurrence.
                            </Alert>}
                        </Fragment>
                    </Form>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={!apptValid || !dirty || this.props.repeatCount >= REPEAT_MAX_TIMES}
                            color="primary"
                            onClick={() => this.props.submit(this.state)}>Save</Button>{' '}
                    <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
                    {this.props.mode === 'edit' &&
                    <Button color="danger" onClick={this.props.delete}>Delete</Button>
                    }
                </ModalFooter>
            </Modal>
        );
    }

}

export default Appointment;