import React, {Component} from 'react';

import {
    Badge,
    Dropdown, DropdownMenu, DropdownToggle, DropdownItem,
    InputGroup, Input, InputGroupAddon,
} from 'reactstrap';

class SettingsPersonSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false,
        };

        this.toggle = this.toggle.bind(this);
        this.delete = this.delete.bind(this);
        this.select = this.select.bind(this);
        this.changeDisplayName = this.changeDisplayName.bind(this);
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    select = async (event) => {
        const {target} = event;

        await this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });

        this.props.onChangeCalendar(this.props.column, target.value);
    };

    changeDisplayName = async (event) => {
        const {target} = event;

        this.props.onChangeCalendarDisplayName(this.props.column, this.props.calendarId, target.value );
    };

    delete = async () => {

        this.props.onDeleteColumn(this.props.column);
    };

    render() {

        const {googleCalendars} = this.props;

        const accessRoleColours = {
            'owner':'primary',
            'writer':'success',
            'reader':'info',
            'freeBusyReader':'secondary',
        }

        const accessRoleNames = {
            'owner':'Owner',
            'reader':'Read Only',
            'writer':'Edit',
            'freeBusyReader':'Free Busy',
        }

        let visibility;
        let displayName;
        for( let i=0; googleCalendars && i<googleCalendars.length; i++) {
            if(googleCalendars[i].id === this.props.calendarId) {
                visibility=googleCalendars[i].accessRole;
                displayName = googleCalendars[i].summaryOverride && googleCalendars[i].summaryOverride.length > 0 ? googleCalendars[i].summaryOverride : googleCalendars[i].summary;
            }
        }

        return (
            <div>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            {this.props.onChangeCalendarDisplayName &&
                                <Input onChange={this.changeDisplayName} placeholder="display name" className='mr-1' value={this.props.displayName}/>}
                            <DropdownToggle color="info" caret size="sm" className='mr-1' >{displayName}</DropdownToggle>
                            <DropdownMenu
                                modifiers={{
                                    setMaxHeight: {
                                        enabled: true,
                                        order: 890,
                                        fn: (data) => {
                                            return {
                                                ...data,
                                                styles: {
                                                    ...data.styles,
                                                    overflow: 'auto',
                                                    maxHeight: 500,
                                                },
                                            };
                                        },
                                    },
                                }}
                            >
                                {
                                    googleCalendars && googleCalendars.map((c, index) =>
                                        <DropdownItem key={c.id}
                                                      value={c.id}
                                                      onClick={this.select}>{c.summaryOverride && c.summaryOverride.length > 0 ? c.summaryOverride : c.summary}
                                            <Badge className='ml-1' color={accessRoleColours[c.accessRole]}>{accessRoleNames[c.accessRole]}</Badge>
                                        </DropdownItem>,
                                    )}
                                <DropdownItem key="_divide" divider/>
                                <DropdownItem key="_remove" onClick={this.delete}>Remove Calendar</DropdownItem>
                            </DropdownMenu>
                            <Badge color={accessRoleColours[visibility]}>{accessRoleNames[visibility]}
                            </Badge>
                        </InputGroupAddon>
                    </InputGroup>
                </Dropdown>
            </div>
        );
    }
}

export default SettingsPersonSelect;