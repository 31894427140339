import React, {Component, Fragment} from 'react';
import Moment from 'moment';
import 'moment/min/locales';
import Event from './Event';
import AnniversaryDay from './AnniversaryDay';

class PersonDay extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render() {
        const {events, date, column} = this.props;
        const {anniversaries} = this.props;

        events.sort(function(a, b) {
            // handle special cases of all day events
            if (typeof a.start.dateTime === 'undefined') {
                return -1;
            }
            if (typeof b.start.dateTime === 'undefined') {
                return 1;
            }
            if (Moment(a.start.dateTime).isBefore(b.start.dateTime)) {
                return -1;
            }
            if (Moment(b.start.dateTime).isBefore(a.start.dateTime)) {
                return 1;
            }
            return 0;
        });

        // define morning, afternoon and evening as specific times of *today*
        // remember that we know all events in this rendering are the same date

        let midday = Moment();
        let dusk = Moment();

        if (events && events[0]) {
            midday = Moment(date).hours(12).minutes(0).seconds(0);
            dusk = Moment(midday).hours(18);
        }

        // now let's put the events into the appropriate array

        let morning = [];
        let afternoon = [];
        let evening = [];

        // handle special case of all day events
        // and multi-day events
        // same day ? display if start is morning
        // multi day ? display if start is morning and cell date is the same as start; or if this is not the first date
        events && events.map(e =>
            (typeof e.start.dateTime === 'undefined' ||
                (Moment(e.start.dateTime).isSame(Moment(e.end.dateTime), 'day') ? Moment(e.start.dateTime).isBefore(midday) :
                    date.clone().isSame(Moment(e.start.dateTime), 'day') ? Moment(e.start.dateTime).isBefore(midday) : true )) && morning.push(e),
        );

        // handle special case of all day events
        // same day ? display if start is afternoon
        // multi day ? display if start is afternoon, and cell date is the same as start
        events && events.map(e =>
            (typeof e.start.dateTime !== 'undefined' &&
                (Moment(e.start.dateTime).isSame(Moment(e.end.dateTime), 'day') ? Moment(e.start.dateTime).isBetween(midday, dusk, null, '[)') :
                    Moment(e.start.dateTime).isBetween(midday, dusk, null, '[)') && date.clone().isSame(Moment(e.start.dateTime), 'day'))) && afternoon.push(e),
        );

        // handle special case of all day events
        // same day ? display if start is evening
        // multi day ? display if start is evening, and cell date is the same as start
        events && events.map(e =>
            (typeof e.start.dateTime !== 'undefined' &&
                (Moment(e.start.dateTime).isSame(Moment(e.end.dateTime), 'day') ? Moment(e.start.dateTime).isSameOrAfter(dusk) :
                    Moment(e.start.dateTime).isSameOrAfter(dusk) && date.clone().isSame(Moment(e.start.dateTime), 'day'))) && evening.push(e),
        );

        return (
            <Fragment>
                {anniversaries && anniversaries.map((anniversary, index) =>
                    anniversary.column === column &&
                    <AnniversaryDay anniversary={anniversary} key={index}/>,
                )}
                <div className="EventCellMorning flex-grow-1">
                    {morning && morning.map(e =>
                        <Event key={e.id}
                               event={e}
                               date={date}
                               column={column}
                               openModal={this.props.openModal}
                               preferences={this.props.preferences}
                        />,
                            )}
                </div>
                <div className="EventCellAfternoon flex-grow-1">
                    {afternoon && afternoon.map(e =>
                        <Event key={e.id}
                               event={e}
                               date={date}
                               column={column}
                               openModal={this.props.openModal}
                               preferences={this.props.preferences}
                        />,
                    )}
                </div>
                <div className="EventCellEvening flex-grow-1">
                    {evening && evening.map(e =>
                        <Event key={e.id}
                               event={e}
                               date={date}
                               column={column}
                               openModal={this.props.openModal}
                               preferences={this.props.preferences}
                        />,
                            )}
                </div>
            </Fragment>
        );
    }
}

export default PersonDay;
