import React from 'react';
import Moment from 'moment';
import 'moment/min/locales';
import GitCommit from './utils/gitInfo.js';

const CalendarFooter = ({unitTesting}) => (
    <div className="CalendarFooter">
        <span>Copyright © 2004-{Moment(unitTesting ? Moment('2018-01-01') : Moment()).format('YY')} sleepylizard.com. All rights reserved.</span>
        <span>{!unitTesting ? GitCommit.gitBranch : 'branch'} / {!unitTesting ? GitCommit.gitTag : '1.0'}.{!unitTesting ? GitCommit.gitCommitsSinceLastTag : '69'} / {Moment(
            !unitTesting ? GitCommit.commitDate : 'Thu, 31 Oct 2019 21:12:40 +0000', Moment.RFC_2822).format('ll LTS')}</span>
    </div>
);

export default CalendarFooter;