import React, {Component} from 'react';
import Moment from 'moment';
import 'moment/min/locales';

class CalendarNavigation extends Component {
// eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render() {
        const {startDay} = this.props;
        const {onCalendarJump} = this.props;

        let monthDisplayLong;
        let monthDisplayShort;
        if (startDay.clone().startOf('week').month() === startDay.clone().endOf('week').month()) {
            // week starts and ends in same month
            monthDisplayLong = startDay.format('MMMM');
            monthDisplayShort = startDay.format('MMM');
            // but not this year
            if (startDay.clone().startOf('week').year() !== Moment().endOf('week').year()) {
                monthDisplayLong += startDay.format('[ ]YYYY');
                monthDisplayShort += startDay.format('[ ]YY');
            }
        } else {
            // week starts and ends in different months
            monthDisplayLong = startDay.clone().startOf('week').format('MMMM') + '-' + startDay.clone().endOf('week').format('MMMM');
            monthDisplayShort = startDay.clone().startOf('week').format('MMM') + '-' + startDay.clone().endOf('week').format('MMM');
            if (startDay.clone().endOf('week').year() !== Moment().endOf('week').year()) {
                // but not this year
                monthDisplayLong += startDay.clone().endOf('week').format('[ ]YYYY');
                monthDisplayShort += startDay.clone().endOf('week').format('[ ]YY');
            }
        }

        return (
            <div className="CalendarHeaderTable">
                <div className="flex flex-row">

                    <div className="NavigateWeekTableCell flex flex-column">
                        <span className="nav-link text-left NavigateLink" title={startDay.clone().subtract(1, 'weeks').startOf('week').format('[w/c ]LL')}
                              onClick={onCalendarJump(startDay.clone().subtract(1, 'weeks'))}>Previous</span>
                        {startDay.isAfter(Moment().endOf('week'), 'day') &&
                        <span className="nav-link text-left NavigateLink"
                              title={Moment(!this.props.unitTesting ? Moment() : Moment('2017-06-12')).clone().startOf('week').format('[Go back to ]LL')}
                              onClick={onCalendarJump(Moment())}>Today</span>}
                    </div>
                    <div className="NavigateMonthsTableCell flex">
                        <div className="flex flex-column text-center">
                            <span
                                className="nav-link NavigateLink"title={startDay.clone().subtract(4, 'months').startOf('week').format('[Go to ]LL')}
                                  onClick={onCalendarJump(startDay.clone().subtract(4, 'months'))}>{startDay.clone().subtract(4, 'months').format('MMM')}</span>
                            <span className="nav-link NavigateLink" title={startDay.clone().subtract(2, 'months').startOf('week').format('[Go to ]LL')}
                                  onClick={onCalendarJump(startDay.clone().subtract(2, 'months'))}>{startDay.clone().subtract(2, 'months').format('MMM')}</span>
                        </div>
                        <div className="flex flex-column text-center">
                            <span className="nav-link NavigateLink" title={startDay.clone().subtract(3, 'months').startOf('week').format('[Go to ]LL')}
                                  onClick={onCalendarJump(startDay.clone().subtract(3, 'months'))}>{startDay.clone().subtract(3, 'months').format('MMM')}</span>
                            <span className="nav-link NavigateLink" title={startDay.clone().subtract(1, 'months').startOf('week').format('[Go to ]LL')}
                                  onClick={onCalendarJump(startDay.clone().subtract(1, 'months'))}>{startDay.clone().subtract(1, 'months').format('MMM')}</span>
                        </div>
                    </div>
                </div>
                <div className="MonthName MonthNameLong">{monthDisplayLong}</div>
                <div className="MonthName MonthNameShort">{monthDisplayShort}</div>
                <div className="flex flex-row">
                    <div className="NavigateMonthsTableCell flex">
                        <div className="flex flex-column text-center">
                            <span className="nav-link NavigateLink" title={startDay.clone().add(1, 'months').startOf('week').format('[Go to ]LL')}
                                  onClick={onCalendarJump(startDay.clone().add(1, 'months'))}>{startDay.clone().add(1, 'months').format('MMM')}</span>
                            <span className="nav-link NavigateLink" title={startDay.clone().add(3, 'months').startOf('week').format('[Go to ]LL')}
                                  onClick={onCalendarJump(startDay.clone().add(3, 'months'))}>{startDay.clone().add(3, 'months').format('MMM')}</span>
                        </div>
                        <div className="flex flex-column text-center">
                            <span className="nav-link NavigateLink" title={startDay.clone().add(2, 'months').startOf('week').format('[Go to ]LL')}
                                  onClick={onCalendarJump(startDay.clone().add(2, 'months'))}>{startDay.clone().add(2, 'months').format('MMM')}</span>
                            <span className="nav-link NavigateLink" title={startDay.clone().add(4, 'months').startOf('week').format('[Go to ]LL')}
                                  onClick={onCalendarJump(startDay.clone().add(4, 'months'))}>{startDay.clone().add(4, 'months').format('MMM')}</span>
                        </div>
                    </div>

                    <div className="NavigateWeekTableCell flex flex-column">
                        <span className="nav-link text-right NavigateLink" title={startDay.clone().add(1, 'weeks').startOf('week').format('[w/c ]LL')}
                              onClick={onCalendarJump(startDay.clone().add(1, 'weeks'))}>Next</span>
                        {startDay.isBefore(Moment().startOf('week'), 'day') &&
                        <span className="nav-link text-right NavigateLink"
                              title={Moment(!this.props.unitTesting ? Moment() : Moment('2017-06-12')).clone().startOf('week').format('[Go back to ]LL')}
                              onClick={onCalendarJump(Moment())}>Today</span>}
                    </div>
                </div>
            </div>
        );
    }
}

export default CalendarNavigation;
