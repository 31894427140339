import React, {Fragment} from 'react';

const CalendarHeader = ({calendars}) => (
    <div className="flex" style={{width: '90%', alignSelf: 'flex-end'}}>
        {calendars && calendars.map((calendar, index) =>
            <Fragment key={calendar.calendarId}>
                <div key={calendar.calendarId}
                     className="PersonName">
                    {calendar.displayName}
                </div>
            </Fragment>,
        )}
    </div>
);

export default CalendarHeader;