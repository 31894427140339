import Moment from 'moment';

function createTooltip(event) {

    let toolTip = '';

    if (event.location && event.location.length) {
        if (event.updated && event.updated !== '') {
            toolTip = `${event.location} (updated ${Moment(event.updated).fromNow()})`;
        } else {
            toolTip = `${event.location}`;
        }
    } else {
        if (event.updated && event.updated !== '') {
            toolTip = `Updated ${Moment(event.updated).fromNow()}`;
        }
    }

    return toolTip;
}

export default createTooltip;