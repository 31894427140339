import React, { Component, Fragment } from 'react';

import FamilyDay from './FamilyDay';
import CalendarNavigation from './CalendarNavigation';
import CalendarHeader from './CalendarHeader';
import ObservedDay from './ObservedDay';
import Appointment from './Appointment';

import Moment from 'moment';
import 'moment/min/locales';
import { NavLink } from 'reactstrap';
import ReactGA from 'react-ga';
import { faTimesCircle,  faInfoCircle, faCheckCircle, faQuestionCircle} from '@fortawesome/free-solid-svg-icons'
import WeatherForecast from './WeatherForecast';

const responsefaIcons = {
    'needsAction': {icon: faQuestionCircle, colour: 'orange', title: 'No reply'},
    'accepted': {icon: faCheckCircle, colour: 'green', title: 'Accepted'},
    'tentative': {icon: faQuestionCircle, colour: 'orange', title: 'Maybe'},
    'decline': {icon: faTimesCircle, colour: 'red', title: 'Declined'},
};

const APPT_OPTIONS_ALL_DAY = 'All Day';

const APPT_REPEAT_OPTION_DOESNT = 'Doesn\'t Repeat';
const APPT_REPEAT_OPTION_DAILY = 'Daily';
const APPT_REPEAT_OPTION_WEEKLY = 'Weekly';
const APPT_REPEAT_OPTION_MONTHLY = 'Monthly';

const DEFAULT_APPT_DURATION_MINS = 60;  // TODO make this a user setting

const APPT_REPEAT_OPTIONS = [
    APPT_REPEAT_OPTION_DOESNT,
    APPT_REPEAT_OPTION_DAILY,
    APPT_REPEAT_OPTION_WEEKLY,
    APPT_REPEAT_OPTION_MONTHLY,
];

function formatEndDate(startDate, repeatType, repeatCount) {
    const unit = repeatType === APPT_REPEAT_OPTION_DAILY
        ? 'days' : repeatType === APPT_REPEAT_OPTION_WEEKLY
            ? 'weeks' : repeatType === APPT_REPEAT_OPTION_MONTHLY
                ? 'months' : 'ms';
    return Moment(startDate).add(repeatCount-1, unit).format('ll');
}

function deriveRecurrenceRule() {
    console.assert(
        this.state.repeatType === APPT_REPEAT_OPTION_DAILY || this.state.repeatType === APPT_REPEAT_OPTION_WEEKLY || this.state.repeatType === APPT_REPEAT_OPTION_MONTHLY,
        'Invalid recurrence type');
    return `RRULE:FREQ=${this.state.repeatType === APPT_REPEAT_OPTION_DAILY ?
        'DAILY' : this.state.repeatType === APPT_REPEAT_OPTION_WEEKLY ?
            'WEEKLY' : this.state.repeatType === APPT_REPEAT_OPTION_MONTHLY ?
                'MONTHLY' : 'ERROR'};COUNT=${this.state.repeatCount}`;
}

class Table extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalMode: '',
            isModal: false,
            optionsValues: [],
            isAllDay: false,
            startTime: Moment(),
            finishTime: Moment(),
            isRecurring: false,
            appointmentDirty: false,    // has the appt been change in the appt dialog
            urlGoogleCalendarEvent: '',
            summary: '',
            location: '',
            cSelected: [],
            event: null,
            infoElements: [],
            repeatType: APPT_REPEAT_OPTION_DOESNT,
            repeatCount: 2,
            conferenceData: false,
        };

        this.openNewAppointmentModal = this.openNewAppointmentModal.bind(this);
        this.openExistingAppointmentModal = this.openExistingAppointmentModal.bind(this);
        this.closeAppointmentModal = this.closeAppointmentModal.bind(this);
        this.submitAppointment = this.submitAppointment.bind(this);
        this.deleteAppointment = this.deleteAppointment.bind(this);
        this.onChangeOptions = this.onChangeOptions.bind(this);
        this.onChangeRepeatType = this.onChangeRepeatType.bind(this);
        this.onChangeRepeatCount = this.onChangeRepeatCount.bind(this);
        this.onChangeStartTime = this.onChangeStartTime.bind(this);
        this.onChangeFinishTime = this.onChangeFinishTime.bind(this);
        this.onChangeSummary = this.onChangeSummary.bind(this);
        this.onChangeLocation = this.onChangeLocation.bind(this);
        this.onCheckboxBtnClick = this.onCheckboxBtnClick.bind(this);

    }

    openNewAppointmentModal(startTime) {

        ReactGA.event({
            category: 'Appointment',
            action: 'Create',
        });

        startTime.hours(Moment().hours()).minutes(0).seconds(0);
        let finishTime=startTime.clone().add(DEFAULT_APPT_DURATION_MINS,'minutes');

        let infoElements = [];
        // if the calendar is read only then add an info element
        for (let c = 0; c < this.props.calendars.length; c++) {
            if(!(this.props.calendars[c].accessRole==='owner'||this.props.calendars[c].accessRole==='writer')) {
                const element = "You won't be able to add or remove "+this.props.calendars[c].displayName+" because you don't have edit access to that calendar.";
                infoElements.push({
                    type: 'warning',
                    value: element,
                    faIcon: faInfoCircle,
                    faColour: "black",
                });
            }
        }

        this.setState({
            modalMode: 'create',
            isModal: !this.state.isModal,
            appointmentDirty: false,
            isAllDay: false,
            optionsValues: [],
            urlGoogleCalendarEvent: '',
            isRecurring: false,
            startTime: startTime,
            finishTime: finishTime,
            infoElements: infoElements,
            repeatType: APPT_REPEAT_OPTION_DOESNT,
            repeatCount: 2,
        });

    }

    openExistingAppointmentModal(event) {

        ReactGA.event({
            category: 'Appointment',
            action: 'Edit',
        });

        let cSelected = [];
        let infoElements = [];

        // for each attendee, find the calendar position and mark the checkbox as on
        for (let a = 0; event.attendees && a < event.attendees.length; a++) {
            let found = false;
            for (let c = 0; c < this.props.calendars.length; c++) {
                if (event.attendees[a].email === this.props.calendars[c].calendarId) {
                    cSelected.push(c);  // NB. if the checkbox is disabled it won't highlight
                    found = true;
                }
            }
            // if not found then the attendee must have been added via google calendar
            if (!found) {
                let attendeeName = event.attendees[a].displayName ? event.attendees[a].displayName : event.attendees[a].email;
                infoElements.push({
                    type: 'info',
                    value: attendeeName + ' has also been invited',
                    faIcon: responsefaIcons[event.attendees[a].responseStatus].icon,
                    faColour: responsefaIcons[event.attendees[a].responseStatus].colour,
                    tooltip: responsefaIcons[event.attendees[a].responseStatus].title,
                });
            }
        }

        // it's possible event was created outside sleepylizard and only has an organiser and no attendees
        // if there's no attendee then the button will be off
        // search for the organiser and switch it on
        for (let c = 0; c < this.props.calendars.length; c++) {
            if (event.organizer.email === this.props.calendars[c].calendarId) {
                if (cSelected.indexOf(c) === -1) {
                    cSelected.push(c);
                }
            }
        }

        // if the calendar is read only then add an info element
        for (let c = 0; c < this.props.calendars.length; c++) {
            if(!(this.props.calendars[c].accessRole==='owner'||this.props.calendars[c].accessRole==='writer')) {
                const element = "You won't be able to add or remove "+this.props.calendars[c].displayName+" because you don't have edit access to that calendar";
                infoElements.push({
                    type: 'warning',
                    value: element,
                    faIcon: faInfoCircle,
                    faColour: 'black',
                });
            }
        }

        this.setState({
            isModal: true,
            modalMode: 'edit',
            appointmentDirty: false,
            event: event,
            isRecurring: event.recurringEventId && event.recurringEventId !== '',
            // only for provide the URL for repeating events that this user is the organiser for
            urlGoogleCalendarEvent: event.recurringEventId && event.recurringEventId !== '' && event.organizer.self ? event.htmlLink : '',
            isAllDay: typeof (event.start.dateTime) === 'undefined',
            optionsValues: typeof (event.start.dateTime) === 'undefined' ? [APPT_OPTIONS_ALL_DAY] : [''],
            startTime: typeof (event.start.dateTime) === 'undefined' ? Moment(event.start.date) : Moment(event.start.dateTime),
            finishTime: typeof (event.end.dateTime) === 'undefined' ? Moment(event.end.date) : Moment(event.end.dateTime),
            summary: event.summary ? event.summary : '',
            location: event.location ? event.location : '',
            cSelected: cSelected,
            infoElements: infoElements,
            repeatType: APPT_REPEAT_OPTION_DOESNT,
            conferenceData: event.conferenceData,
        });
    }

    closeAppointmentModal() {

        ReactGA.event({
            category: 'Appointment',
            action: 'Cancel',
        });

        this.setState({
            isModal: false,
            appointmentDirty: false,
            // clear out the modal for next time
            isAllDay: false,
            urlGoogleCalendarEvent: '',
            isRecurring: false,
            optionsValues: [],
            summary: '',
            location: '',
            cSelected: [],
            repeatType: APPT_REPEAT_OPTION_DOESNT,
            repeatCount: 2,
        });
    }

    submitAppointment() {

        let attendees = [];
        this.state.cSelected.sort();
        // eslint-disable-next-line
        this.state.cSelected.map((button) => {
            attendees.push({
                email: this.props.calendars[button].calendarId,
                displayName: this.props.calendars[button].displayName,
                // organiser is this user if new; the old one if updating
                organizer: this.state.modalMode === 'create'? this.props.calendars[button].calendarId === this.props.myId : this.props.calendars[button].calendarId === this.state.event.organizer.email,
                responseStatus: 'accepted',
                comment: 'auto accepted by sleepylizard',
            });
        });

        ReactGA.event({
            category: 'Appointment',
            action: 'Save',
            label: this.state.modalMode === 'create' ? 'Create' : 'Update',
            value: attendees.length,
        });

        if (this.state.modalMode === 'create') {
            const eventResource = {
                kind: 'calendar#event',
                summary: this.state.summary,
                location: this.state.location,
                attendees: attendees,
                organizer: {
                    email: attendees[0].email,
                },
                start: {},
                end: {},
                source: {
                    title: 'Family Calendar',
                    url: 'http://www.sleepylizard.com',   // TODO add a URL to the correct week or event
                },
            };
            if (this.state.isAllDay) {
                eventResource.start.date = this.state.startTime.format('YYYY-MM-DD');
                eventResource.end.date = this.state.startTime.clone().add(1, 'days').format('YYYY-MM-DD');
            } else {
                eventResource.start.dateTime = this.state.startTime.toISOString();
                eventResource.end.dateTime = this.state.finishTime.toISOString();
            }

            if (this.state.repeatType !== APPT_REPEAT_OPTION_DOESNT) {
                eventResource['recurrence'] = [deriveRecurrenceRule.call(this)];
                eventResource['start']['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
                eventResource['end']['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
            }
            this.props.createAppt(eventResource);
        } else {
            // this.state.modalMode === 'edit'

            let eventResource = JSON.parse(JSON.stringify(this.state.event));   // deep clone

            // 1. is the original organiser still an attendee
            var organizer = attendees.find(({email}) => email === eventResource.organizer.email);
            if (typeof organizer === 'undefined') {
                // find the first attendees whose calendar is writable
                for (let i = 0; i < attendees.length; i++) {
                    if ('undefined' !== typeof this.props.calendars.find(({calendarId, accessRole}) => calendarId === attendees[i].email && (accessRole === 'owner' || accessRole === 'writer'))) {
                        organizer = {email: attendees[i].email};
                        break;
                    }
                }
            }

            // 2. add back external attendees
            let newAttendees = attendees; // copy attendees from appt dialog
            for (let i = 0; eventResource.attendees && i < eventResource.attendees.length; i++) {
                // are they in our calendars? if not, they're external so add them
                const attendee = this.props.calendars.find(({calendarId}) => calendarId === eventResource.attendees[i].email);
                if (typeof attendee === 'undefined') {
                    newAttendees.push(eventResource.attendees[i]);
                }
            }
            eventResource.attendees = newAttendees;

            eventResource.summary = this.state.summary;
            eventResource.location = this.state.location;

            if (this.state.isAllDay) {
                eventResource.start.date = this.state.startTime.format('YYYY-MM-DD');
                eventResource.end.date = this.state.startTime.clone().add(1, 'days').format('YYYY-MM-DD');
                delete eventResource.start.dateTime;
                delete eventResource.end.dateTime;
            } else {
                eventResource.start.dateTime = this.state.startTime.toISOString();
                eventResource.end.dateTime = this.state.finishTime.toISOString();
                delete eventResource.start.date;
                delete eventResource.end.date;
            }
            if (this.state.repeatType !== APPT_REPEAT_OPTION_DOESNT) {
                eventResource['recurrence'] = [deriveRecurrenceRule.call(this)];
                eventResource['start']['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
                eventResource['end']['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
            }

            let event = {
                organizer: organizer,
                eventResource: eventResource,
            };
            this.props.updateAppt(event);
        }

        this.setState({
            isModal: false,
            appointmentDirty: false,
            // clear out the modal for next time
            isAllDay: false,
            urlGoogleCalendarEvent: '',
            isRecurring: false,
            optionsValues: [],
            summary: '',
            location: '',
            cSelected: [],
            repeatType: APPT_REPEAT_OPTION_DOESNT,
            repeatCount: 2,
        });

    }

    deleteAppointment() {
        this.props.deleteAppt(this.state.event);

        this.setState({
            isModal: false,
            appointmentDirty: false,
            // clear out the modal for next time
            isAllDay: false,
            urlGoogleCalendarEvent: '',
            isRecurring: false,
            optionsValues: [],
            summary: '',
            location: '',
            cSelected: [],
            repeatType: APPT_REPEAT_OPTION_DOESNT,
            repeatCount: 2,
        });
    }

    onChangeOptions(optionsValues) {
        this.setState({optionsValues});
        this.setState({appointmentDirty: true});
        this.setState({isAllDay: optionsValues.indexOf(APPT_OPTIONS_ALL_DAY) !== -1});
    }

    onChangeRepeatCount(repeatCount) {
        this.setState({repeatFormattedEndDate: formatEndDate(this.state.startTime, this.state.repeatType, repeatCount)});
        this.setState({repeatCount: repeatCount});
        this.setState({appointmentDirty: true});
    }

    onChangeRepeatType(repeatType) {
        this.setState({repeatFormattedEndDate: formatEndDate(this.state.startTime, repeatType, this.state.repeatCount)});
        this.setState({repeatType: repeatType});
        this.setState({appointmentDirty: true});
    }

    onChangeStartTime(startTime) {
        // check that we haven't entered some garbage into the text box
        // if so reset it
        if (Moment(startTime).isValid()) {
            let duration = this.state.finishTime.diff(this.state.startTime, 'seconds');
            this.setState({startTime: Moment(startTime).seconds(0)});
            this.setState({finishTime: Moment(startTime).seconds(0).add(duration, 'seconds')});
            this.setState({appointmentDirty: true});
        } else {
            this.setState({startTime: Moment(this.state.startTime)});
        }
    }

    onChangeFinishTime(finishTime) {
        // check that we haven't entered some garbage into the text box
        // if so reset it
        if (Moment(finishTime).isValid()) {
            this.setState({finishTime: Moment(finishTime)});
            this.setState({appointmentDirty: true});
        } else {
            console.log(Moment(this.state.startTime));
            this.setState({finishTime: Moment(this.state.startTime).add(DEFAULT_APPT_DURATION_MINS,'minutes')});
        }
    }

    onChangeSummary(event) {
        event.persist();
        this.setState({summary: event.target.value});
        this.setState({appointmentDirty: true});
    }

    onChangeLocation(event) {
        event.persist();
        this.setState({location: event.target.value});
        this.setState({appointmentDirty: true});
    }

    onCheckboxBtnClick(selected) {
        const index = this.state.cSelected.indexOf(selected);

        // cSelected stores the calendars that have been selected
        // index will be -1 if the selected button (in this call) is not selected
        if (index < 0) {
            // in which case add it to the list of selected calendars
            this.state.cSelected.push(selected);
        } else {
            // otherwise, remove it from the list
            this.state.cSelected.splice(index, 1);
        }

        this.setState({cSelected: [...this.state.cSelected]});
        this.setState({appointmentDirty: true});
    }

    render() {

        // events[nPersons]=array(of Events)
        // ie an array of person events objects - Events

        const {events} = this.props;
        const {calendars} = this.props;
        const {calendarDays} = this.props;
        const {observedDays} = this.props;
        const {anniversaries} = this.props;
        const {startDay} = this.props;
        const {onCalendarJump} = this.props;
        const {weather} = this.props;

        // make sure the calendar columns are in order defined in the props not array order

        calendars.sort(function(a, b) {
            if (a.column < b.column) {
                return -1;
            }
            if (b.column < a.column) {
                return 1;
            }
            return 0;
        });

        // we need to take incoming events which are organised as an array (1 per person) of Events objects
        // and reorganise into an array (1 per day) of arrays (1 person) of items

        // 1. initialise the empty structure
        let dayEvents = new Array(7);   // 1 per day
        for (let dow = 0; dow <= 6; dow++) {
            dayEvents[dow] = new Array(events.length);
            for (let person = 0; person < events.length; person++) {
                dayEvents[dow][person] = [];
            }
        }

        // 2. now fill it
        // dayEvents[nDays][nPersons]=array(of items)
        for (let person = 0; person < events.length; person++) {
            for (let evt = 0; evt < events[person].events.length; evt++) {
                events[person].events[evt].accessRole = events[person].accessRole;
                if (typeof events[person].events[evt].start.dateTime === 'undefined') {
                    // all day event
                    // the end date is always the next day (counter intuitive)
                    // all day events can repeat too
                    // all day events can span multiple days too
                    let daysSpanned = Moment(events[person].events[evt].end.date).endOf('day').diff(Moment(events[person].events[evt].start.date).startOf('day'), 'days');
                    for (let day = 0; day < daysSpanned; day++) {
                        let date = Moment(events[person].events[evt].start.date).add(day, 'days');
                        // test whether the date lies in this week
                        if (date.isBetween(startDay, startDay.clone().add(6, 'days'), 'days', '[]')) {
                            let dow = date.weekday();
                            dayEvents[dow][events[person].person].push(events[person].events[evt]);
                        }
                    }
                } else if (Moment(events[person].events[evt].start.dateTime).isSame(Moment(events[person].events[evt].end.dateTime), 'day')) {
                    // event starts/ends on same day
                    let dow = Moment(events[person].events[evt].start.dateTime).weekday();
                    dayEvents[dow][events[person].person].push(events[person].events[evt]);
                } else {
                    // event starts/ends on different days
                    // work out the days of the week on which this event overlaps
                    // the event object will adjust the display times
                    let daysSpanned = Moment(events[person].events[evt].end.dateTime).endOf('day').diff(Moment(events[person].events[evt].start.dateTime).startOf('day'), 'days') + 1;
                    for (let day = 0; day < daysSpanned; day++) {
                        if (Moment(events[person].events[evt].start.dateTime).add(day, 'days').isSame(startDay.clone(), 'week')) {
                            let dow = Moment(events[person].events[evt].start.dateTime).add(day, 'days').weekday();
                            dayEvents[dow][events[person].person].push(events[person].events[evt]);
                        }
                    }
                }
            }
        }

        // first add observed days to an array per dow
        let observedDates = new Array(7);
        for (let i = 0; i < 7; i++) {
            observedDates[i] = [];
        }

        for (let i = 0; i < observedDays.length; i++) {
            let observedDate = observedDays[i].start.date ? observedDays[i].start.date : observedDays[i].start.dateTime;
            let dow = Moment(observedDate).startOf('day').weekday();
            observedDates[dow].push(observedDays[i]);
        }

        // now add anniversaries to an array per dow
        let anniversaryDates = new Array(7);
        for (let i = 0; i < 7; i++) {
            anniversaryDates[i] = [];
        }

        for (let i = 0; i < anniversaries.length; i++) {
            var associatedCalendar = calendars.find(({calendarId}) => calendarId === anniversaries[i].associatedCalendarId);
            if (typeof associatedCalendar !== 'undefined') {
                anniversaries[i]['column'] = associatedCalendar.column;
                let anniversaryDate = anniversaries[i].start.date ? anniversaries[i].start.date : anniversaries[i].start.dateTime;
                let dow = Moment(anniversaryDate).startOf('day').weekday();
                anniversaryDates[dow].push(anniversaries[i]);
            }
        }

        return (
            <Fragment>
                <Appointment isOpen={this.state.isModal}
                             submit={this.submitAppointment}
                             toggle={this.closeAppointmentModal}
                             delete={this.deleteAppointment}
                             summary={this.state.summary}
                             location={this.state.location}
                             isRecurring={this.state.isRecurring}
                             dirty={this.state.appointmentDirty}
                             urlGoogleCalendarEvent={this.state.urlGoogleCalendarEvent}
                             onChangeSummary={this.onChangeSummary}
                             onChangeLocation={this.onChangeLocation}
                             selected={this.state.cSelected}
                             onCheckboxBtnClick={this.onCheckboxBtnClick}
                             optionsData={[APPT_OPTIONS_ALL_DAY]}
                             isAllDay={this.state.isAllDay}
                             optionsValues={this.state.optionsValues}
                             onChangeOptions={this.onChangeOptions}
                             onChangeRepeatCount={this.onChangeRepeatCount}
                             onChangeRepeatType={this.onChangeRepeatType}
                             repeatType={this.state.repeatType}
                             repeatCount={this.state.repeatCount}
                             repeatOptions={APPT_REPEAT_OPTIONS}
                             repeatFormattedEndDate={this.state.repeatFormattedEndDate}
                             startTime={this.state.startTime}
                             finishTime={this.state.finishTime}
                             onChangeStartTime={this.onChangeStartTime}
                             onChangeFinishTime={this.onChangeFinishTime}
                             calendars={calendars}
                             myId={this.props.myId}
                             mode={this.state.modalMode}
                             infoElements={this.state.infoElements}
                             conferenceData={this.state.conferenceData}
                             preferences={this.props.preferences}
                />
                <CalendarNavigation
                    startDay={startDay}
                    onCalendarJump={onCalendarJump}
                    unitTesting={this.props.unitTesting}
                />
                <div className="flex-col CalendarTable">
                    <CalendarHeader calendars={calendars} />
                    {dayEvents && dayEvents.map((weekday, dow) =>
                        <div className="flex-row flex-grow-1" key={'tr' + dow}>
                            <div key={'td' + dow} className={`DayOfWeekCell  ${Moment().isSame(calendarDays[dow], 'day') ? ' DayOfWeekCellToday' : ''}`}>
                                <WeatherForecast date={calendarDays[dow]} weather={weather}/>
                                <NavLink onClick={() => this.openNewAppointmentModal(calendarDays[dow])}>
                                    <div className='DayOfMonth mb-1'>
                                        {Moment(calendarDays[dow]).format('D')}
                                    </div>
                                    <div className='DayOfWeek DayOfWeekShort'>
                                        {Moment(calendarDays[dow]).format('ddd')}
                                    </div>
                                    <div className='DayOfWeek DayOfWeekLong'>
                                        {Moment(calendarDays[dow]).format('dddd')}
                                    </div>
                                </NavLink>
                                {observedDates && observedDates[dow] && observedDates[dow].map((observedDay) =>
                                    <div key={observedDay.id}>
                                        <ObservedDay observedDay={observedDay}/>
                                    </div>,
                                )}
                            </div>
                            <FamilyDay key={'fd' + dow}
                                       events={weekday}
                                       date={calendarDays[dow]}
                                       anniversaries={anniversaryDates[dow]}
                                       unitTesting={this.props.unitTesting}
                                       openModal={this.openExistingAppointmentModal}
                                       preferences={this.props.preferences}
                            />
                        </div>,
                    )}
            </div>
            </Fragment>
        );
    }
}

export default Table;