import React, { Component, Fragment } from 'react';

import FamilyCalendar from './FamilyCalendar';
import Subscribe from './Subscribe';

import { BrowserRouter, Route, NavLink } from 'react-router-dom';

import ReactGA from 'react-ga';
import 'autotrack/lib/plugins/url-change-tracker';
import 'autotrack/lib/plugins/clean-url-tracker';
import 'autotrack/lib/plugins/page-visibility-tracker';

import Moment from 'moment';
import 'moment/min/locales';

const GA_TRACKING_ID = 'UA-8938996-2';

ReactGA.initialize(GA_TRACKING_ID, {
    titleCase: false,
    gaOptions: {
        siteSpeedSampleRate: 100,
    },
});
ReactGA.plugin.require('urlChangeTracker');
ReactGA.plugin.require('cleanUrlTracker');
ReactGA.plugin.require('pageVisibilityTracker', {
    sendInitialPageview: true,
    timeZone: 'Europe/London',
});

// eslint-disable-next-line
const MainMenu = () => {
    return (
        <span>
            <NavLink exact to="/" className="CalendarMenuItem" activeClassName="selected">
                <span>calendar</span>
            </NavLink>&nbsp;
        </span>
    );
};

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDay: Moment().startOf('week'),
        };
    }

    CalendarPage = () => <FamilyCalendar />;

    SubscribePage = () => <Subscribe />;

    render() {
        return (
            <BrowserRouter>
                <Fragment>
                    <Route exact path="/" component={this.CalendarPage} />
                    <Route exact path="/subscribe" component={this.SubscribePage} />
                </Fragment>
            </BrowserRouter>
        );
    }

}

export default App;
