import React, {Component, Fragment} from 'react';

import 'moment/min/locales';
import 'react-widgets/dist/css/react-widgets.css';

import {Button, Form, FormGroup, UncontrolledAlert} from 'reactstrap';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink ,Container, Row, Col} from 'reactstrap';
import classnames from 'classnames';
import SelectList from "react-widgets/lib/SelectList";

import SettingsPersonSelect from './SettingsPersonSelect';

class Settings extends Component {
    constructor(props) {
        super(props);

        this.changeCalendar = this.changeCalendar.bind(this);

        this.state = {
            activeTab: '1',
        };

    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    changeCalendar = async (column, calendarId) => {
        this.props.onChangeCalendar(column, calendarId);
    };

    render() {

        const {dirty} = this.props;

        // sanity check all the calendars for duplicates and read only

        // 1.  family members

        // find if we're trying to put the same calendar in 2 columns
        // sort by calendarId and then check if 2 successive entries are the same
        var sortedCalendars = this.props.calendars.slice().sort(function(a, b) {
            if (a.calendarId < b.calendarId) {
                return -1;
            }
            if (a.calendarId > b.calendarId) {
                return 1;
            }
            return 0;
        });

        // check for duplicates
        var duplicateCalendars = [];
        for (let i = 0; i < sortedCalendars.length - 1; i++) {
            if (sortedCalendars[i + 1].calendarId === sortedCalendars[i].calendarId) {
                // found a dupe
                // only add it to the list if it's not there already
                if ('undefined' === typeof duplicateCalendars.find(({id}) => sortedCalendars[i].calendarId === id)) {
                    // add the full google calendar object
                    duplicateCalendars.push(this.props.accessibleCalendars.find(({id}) => sortedCalendars[i].calendarId === id));
                }
            }
        }

        // check for read only
        var readOnlyCalendars = [];
        for (let i = 0; i < sortedCalendars.length; i++) {
            let cal = this.props.accessibleCalendars.find(({id}) => sortedCalendars[i].calendarId === id);
            // check permissions and only add if it's not already been added
            if (cal && cal.accessRole !== 'owner' && cal.accessRole !== 'writer' && 'undefined' === typeof readOnlyCalendars.find(({id}) => sortedCalendars[i].calendarId === id)) {
                readOnlyCalendars.push(cal);
            }
        }

        // 2.  observed calendars

        // find if we're trying to put the same calendar in 2 columns
        // sort by calendarId and then check if 2 successive entries are the same
        var sortedObservedCalendars = this.props.observedCalendars.slice().sort(function(a, b) {
            if (a.calendarId < b.calendarId) {
                return -1;
            }
            if (a.calendarId > b.calendarId) {
                return 1;
            }
            return 0;
        });

        // check for duplicates
        var duplicateObservedCalendars = [];
        for (let i = 0; i < sortedObservedCalendars.length - 1; i++) {
            if (sortedObservedCalendars[i + 1].calendarId === sortedObservedCalendars[i].calendarId) {
                // found a dupe
                // only add it to the list if it's not there already
                if ('undefined' === typeof duplicateObservedCalendars.find(({id}) => sortedObservedCalendars[i].calendarId === id)) {
                    // add the full google calendar object
                    duplicateObservedCalendars.push(this.props.accessibleCalendars.find(({id}) => sortedObservedCalendars[i].calendarId === id));
                }
            }
        }

        // 3.  anniversary calendars

        // find if we're trying to put the same calendar in 2 columns
        // sort by calendarId and then check if 2 successive entries are the same
        var sortedAnniversaryCalendars = this.props.anniversaryCalendars.slice().sort(function(a, b) {
            if (a.calendarId < b.calendarId) {
                return -1;
            }
            if (a.calendarId > b.calendarId) {
                return 1;
            }
            return 0;
        });

        // check for duplicates
        var duplicateAnniversaryCalendars = [];
        for (let i = 0; i < sortedAnniversaryCalendars.length - 1; i++) {
            if (sortedAnniversaryCalendars[i + 1].calendarId === sortedAnniversaryCalendars[i].calendarId) {
                // found a dupe
                // only add it to the list if it's not there already
                if ('undefined' === typeof duplicateAnniversaryCalendars.find(({id}) => sortedAnniversaryCalendars[i].calendarId === id)) {
                    // add the full google calendar object
                    duplicateAnniversaryCalendars.push(this.props.accessibleCalendars.find(({id}) => sortedAnniversaryCalendars[i].calendarId === id));
                }
            }
        }

        return (
            <Modal backdrop={false} isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}>
                <ModalHeader toggle={this.props.toggle}>Calendar Settings</ModalHeader>
                <ModalBody>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                            >
                                Family
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}
                            >
                                Holidays
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => { this.toggle('3'); }}
                            >
                                Anniversaries
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({active: this.state.activeTab === '4'})}
                                onClick={() => {
                                    this.toggle('4');
                                }}
                            >
                                Preferences
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <Container>
                                <Row className="mt-1">
                                    <Col>Click 'Add Family Member' for each family member you would like to add.
                                        Their events will display left to right on the calendar.
                                    </Col>
                                </Row>
                                <Row className="mt-1">
                                    <Col>
                                        The calendar headings are displayed below along with your calendar permissions.
                                    </Col>
                                </Row>
                                <Row className="mt-1 mb-2">
                                    <Col>When you click on the family member dropdown arrow you can choose from your calendars.
                                        To remove someone, choose 'Remove Calendar' from the bottom of the list.
                                    </Col>
                                </Row>
                                {
                                    this.props.calendars && this.props.calendars.map((c, index) =>
                                        <Row className="mb-1" key={index}>
                                            <Col>
                                                <SettingsPersonSelect column={index}
                                                                      calendarId={c.calendarId}
                                                                      displayName={c.displayName}
                                                                      googleCalendars={this.props.accessibleCalendars}
                                                                      onChangeCalendar={this.changeCalendar}
                                                                      onDeleteColumn={this.props.onDeleteCalendar}
                                                                      onChangeCalendarDisplayName={this.props.onChangeCalendarDisplayName}
                                                />
                                            </Col>
                                        </Row>,
                                    )}
                                <Row className="mt-2">
                                    <Col>
                                        <Button onClick={() => {this.props.onAddCalendar();}} size="sm">Add Family Member +</Button><br/>
                                    </Col>
                                </Row>
                                {duplicateCalendars && duplicateCalendars.map((dupe) =>
                                    <Fragment key={dupe.id}>
                                        <UncontrolledAlert color='danger' className="mt-2">
                                            You can only add '{dupe.summary}' once as a family member
                                        </UncontrolledAlert>
                                    </Fragment>,
                                )}
                                {readOnlyCalendars && readOnlyCalendars.map((readOnly) =>
                                    <Fragment key={readOnly.id}>
                                        <UncontrolledAlert color='info' className="mt-2">
                                            You can add {readOnly.summary} but because you don't have Edit permissions
                                            you won't be able to add them to appointments
                                        </UncontrolledAlert>
                                    </Fragment>,
                                )}
                            </Container>
                        </TabPane>
                        <TabPane tabId="2">
                            <Container>
                                <Row className="mt-1">
                                    <Col>You can choose one of more calendars to show Bank Holidays or sports matches.
                                        It's events will display in the left hand date column.
                                    </Col>
                                </Row>
                                <Row className="mt-1">
                                    <Col>Click 'Add Observed Calendar' for each calendar you would like to add.
                                    </Col>
                                </Row>
                                <Row className="mt-1 mb-2">
                                    <Col>When you click on the calendar dropdown arrow you can choose from your calendars.
                                        To remove an observed calendar, choose 'Remove Calendar' from the bottom of the list.
                                    </Col>
                                </Row>
                                {
                                    this.props.observedCalendars && this.props.observedCalendars.map((c, index) =>
                                        <Row className="mb-1" key={index}>
                                            <Col>
                                                <SettingsPersonSelect key={c.calendarId}
                                                                      calendarId={c.calendarId}
                                                                      column={index}
                                                                      googleCalendars={this.props.accessibleCalendars}
                                                                      onChangeCalendar={this.props.onChangeObservedCalendar}
                                                                      onDeleteColumn={this.props.onDeleteObservedCalendar}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                {this.props.observedCalendars &&
                                <Row className="mt-2">
                                    <Col>
                                        <Button onClick={this.props.onAddObservedCalendar} size="sm">Add Observed Calendar +</Button>
                                    </Col>
                                </Row>
                                }
                                {duplicateObservedCalendars && duplicateObservedCalendars.map((dupe) =>
                                    <Fragment key={dupe.id}>
                                        <UncontrolledAlert color='danger' className="mt-2">
                                            You can only add '{dupe.summary}' once as an Observed Calendar
                                        </UncontrolledAlert>
                                    </Fragment>,
                                )}
                            </Container>
                        </TabPane>
                        <TabPane tabId="3">
                            <Container>
                                <Row className="mt-1">
                                    <Col>You can choose one of more calendars to show Birthdays and Anniversaries.
                                        These will be displayed in your calendar column.
                                    </Col>
                                </Row>
                                <Row className="mt-1">
                                    <Col>Click 'Add Anniversary Calendar' for each calendar you would like to add.
                                    </Col>
                                </Row>
                                <Row className="mt-1 mb-2">
                                    <Col>When you click on the calendar dropdown arrow you can choose from your calendars.
                                        To remove an anniversary calendar, choose 'Remove Calendar' from the bottom of the list.
                                    </Col>
                                </Row>
                                {
                                    this.props.anniversaryCalendars && this.props.anniversaryCalendars.map((c, index) =>
                                        <Row className="mb-1" key={index}>
                                            <Col>
                                                <SettingsPersonSelect key={c.calendarId}
                                                                      calendarId={c.calendarId}
                                                                      column={index}
                                                                      googleCalendars={this.props.accessibleCalendars}
                                                                      onChangeCalendar={this.props.onChangeAnniversaryCalendar}
                                                                      onDeleteColumn={this.props.onDeleteAnniversaryCalendar}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                {this.props.anniversaryCalendars &&
                                <Row className="mt-2">
                                    <Col>
                                        <Button onClick={this.props.onAddAnniversaryCalendar} size="sm">Add Anniversary Calendar +</Button>
                                    </Col>
                                </Row>
                                }
                                {duplicateAnniversaryCalendars && duplicateAnniversaryCalendars.map((dupe) =>
                                    <Fragment key={dupe.id}>
                                        <UncontrolledAlert color='danger' className="mt-2">
                                            You can only add '{dupe.summary}' once as an Anniversary Calendar
                                        </UncontrolledAlert>
                                    </Fragment>,
                                )}
                            </Container>
                        </TabPane>
                        <TabPane tabId="4">
                            <Container>
                                <br/>
                                <Form>
                                    <FormGroup className="ApptFormGroup mb-1">
                                        <SelectList className="ApptOptions"
                                                    multiple
                                                    data={this.props.preferencesData}
                                                    defaultValue={[]}
                                                    value={this.props.preferencesValues}
                                                    onChange={this.props.onChangePreferences}
                                        />
                                    </FormGroup>
                                </Form>
                            </Container>
                        </TabPane>
                    </TabContent>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={duplicateCalendars.length>0 || duplicateObservedCalendars.length>0 || duplicateAnniversaryCalendars.length>0 || !dirty} color="primary" onClick={() => this.props.submit(this.state)}>Save</Button>{' '}
                    <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }

}

export default Settings;