import React, {Component} from 'react';
import Moment from 'moment';
import 'moment/min/locales';
import PersonDay from './PersonDay';
class FamilyDay extends Component {
// eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render() {
        const {events} = this.props;
        const {anniversaries} = this.props;
        const {date} = this.props;

        events && events.map(event =>
            event.sort(function(a, b) {
                if (Moment(a.start.dateTime).isBefore(b.start.dateTime)) {
                    return -1;
                }
                if (Moment(b.start.dateTime).isBefore(a.start.dateTime)) {
                    return 1;
                }
                return 0;
            }),
        );

        return (
            events && events.map((event, col) =>
                <div key={col} className={`flex-col CalendarTableCell ${Moment(!this.props.unitTesting ? Moment() : Moment('2019-06-12')).isSame(date, 'day')
                    ? ' CalendarTableCellToday'
                    : ''}`}>
                    <PersonDay anniversaries={anniversaries}
                               events={event}
                               column={col + 1}
                               date={date}
                               openModal={this.props.openModal}
                               preferences={this.props.preferences}
                    />
                </div>,
            )
        );
    }

}

export default FamilyDay;
